import React from 'react';

import { SvgWrapper } from '../SvgWrapper';

export const MUIIcon: React.FC = () => (
  <SvgWrapper viewBox="0 0 24 24" title="Material-UI">
    <path
      d="M8,16.61V15.37L14,11.91V7.23L9,10.12L4,7.23V13L3,13.58L2,13V5L3.07,4.38L9,7.81L12.93,5.54L14.93,4.38L16,5V13.06L10.92,16L14.97,18.33L20,15.43V11L21,10.42L22,11V16.58L14.97,20.64L8,16.61M22,9.75L21,10.33L20,9.75V8.58L21,8L22,8.58V9.75Z"
      fill="#0081cb"
    />
  </SvgWrapper>
);
